import React from 'react';
import { ModalPageContainer } from '../../styled';
import UpdatePasswordView from '../../views/AccountRecovery/UpdatePasswordView';

const UpdatePassword = () => {
  return (
    <ModalPageContainer>
      <UpdatePasswordView />
    </ModalPageContainer>
  );
};

export default UpdatePassword;
