import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Notifier from '../../Notifier';
import {
  FormError,
  FormHeaderLabel,
  FormHeaderWrapper,
  FormInput,
  FormLabel,
  FormSection,
  FormSubmitButton,
  FormWrapper,
  InputWrapper,
  LoginFormButtonsContainer,
  PageContainer,
  PageLogo
} from '../../components/Shared/Forms/Forms';
import { Container } from '../../components/Shared/Shared';
import PageLoader from '../../components/Shared/Spinner';
import useIcons from '../../hooks/useIcons';
import { GetBusUserByForgetPasswordToken, UpdateBusUserPasswordByForgetPasswordToken } from '../../queries';
import { isValidUUID } from '../../utils/validators';

type UpdatePasswordFormProps = {
  user: any;
  onSubmitError: ApolloError | undefined;
  onSubmitLoading: boolean;
  onSubmit: (variables: any) => void;
};

const UpdatePasswordForm = ({ user, onSubmitError, onSubmitLoading, onSubmit }: UpdatePasswordFormProps) => {
  const icons = useIcons();
  const { control, handleSubmit, errors } = useForm();
  const passwords = useWatch({ control, name: ['newPassword', 'confirmPassword'] });
  const updatePassword = handleSubmit(onSubmit);

  return (
    <PageContainer>
      <PageLogo alt={'Collar Logo'} image={icons.logoLarge.childImageSharp.gatsbyImageData} />
      <FormWrapper display={'flex'} flexDirection={'column'} maxWidth={480} width={'480px'}>
        <FormSection alignItems={'center'}>
          <FormHeaderWrapper>
            <FormHeaderLabel margin={'32px 0 32px 0'}>Reset Your Password</FormHeaderLabel>
          </FormHeaderWrapper>
          {user ? (
            <>
              <Container>
                <InputWrapper>
                  <FormLabel error={errors.email}>Email</FormLabel>
                  <FormInput disabled value={user?.email || ''} />
                </InputWrapper>
                <InputWrapper>
                  <FormLabel error={errors.newPassword}>New Password</FormLabel>
                  <Controller
                    as={<FormInput error={errors.newPassword} type={'password'} />}
                    control={control}
                    name={'newPassword'}
                    defaultValue={''}
                    rules={{
                      required: true,
                      minLength: 8
                    }}
                  />
                  {errors.newPassword && <FormError>{errors.newPassword.message || 'New Password is required'}</FormError>}
                </InputWrapper>
                <InputWrapper>
                  <FormLabel error={errors.confirmPassword}>Re-type Password</FormLabel>
                  <Controller
                    as={<FormInput error={errors.confirmPassword} type={'password'} />}
                    control={control}
                    name={'confirmPassword'}
                    defaultValue={''}
                    rules={{
                      required: true,
                      minLength: 8
                    }}
                  />
                  {errors.confirmPassword && <FormError>{errors.confirmPassword.message || 'Confirm Password is required'}</FormError>}
                  {passwords.newPassword !== passwords.confirmPassword && !!passwords.confirmPassword && <FormError>{'Confirm Password doesnt match the new password'}</FormError>}
                </InputWrapper>
              </Container>
              <LoginFormButtonsContainer>
                <FormSubmitButton onClick={updatePassword} error={!!onSubmitError} loading={onSubmitLoading}>
                  Update
                </FormSubmitButton>
              </LoginFormButtonsContainer>
            </>
          ) : (
            <PageLoader size={25} />
          )}
        </FormSection>
      </FormWrapper>
    </PageContainer>
  );
};

const UpdatePasswordView = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [_, userId] = isValidUUID(urlParams.get('userId') || '') || [''];
  const token = urlParams.get('token') || '';

  const [getUser, { data: { getBusUserByForgetPasswordToken: user } = {}, error }] = useLazyQuery(GetBusUserByForgetPasswordToken, { fetchPolicy: 'network-only' });
  const [updatePassword, { data: updatedUser, loading: onSubmitLoading, error: onSubmitError }] = useMutation(UpdateBusUserPasswordByForgetPasswordToken, { fetchPolicy: 'no-cache' });

  const onSubmit = (variables: any) => {
    if (variables?.confirmPassword !== variables?.newPassword) {
      return;
    }
    updatePassword({
      variables: {
        password: variables?.newPassword,
        userId: user?.id,
        token
      },
      fetchPolicy: 'no-cache'
    });
  };

  useEffect(() => {
    if (!userId || !token) {
      navigate('/login');
      return;
    }
    getUser({ variables: { userId, token } });
  }, [userId]);

  useEffect(() => {
    if (error) {
      navigate('/login');
      return;
    }
  }, [error]);

  useEffect(() => {
    if (updatedUser) {
      Notifier.success({ message: 'Password', description: 'Changed Successfuly' });
      navigate('/login', { replace: true });
    }
  }, [updatedUser]);

  return <UpdatePasswordForm {...{ onSubmit, user, onSubmitError, onSubmitLoading }} />;
};

export default UpdatePasswordView;
